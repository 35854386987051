var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"receival-archive","bladeName":"receival-archives","bladesData":_vm.bladesData,"getParams":function (bladeData) { return { includeDetails: false, destinationLocationID: bladeData.data != null ? bladeData.data.locationID : null }},"headers":[
        { text: 'Location', value: 'destinationLocationID', display: true },
        { text: 'Sender', value: 'sellerID', display: true },
        { text: 'Courier', value: 'courierID', display: true },
        { text: 'Delivered On', value: 'deliveredOn', textFilter: 'toShortDateAndTime' }],"hideActions":"","hideHeader":"","itemProperties":['ID', 'DestinationLocationID', 'SellerID', 'CourierID', 'DeliveredOn'],"minimizeOnSelect":"","navigation":"receival-archives","title":"Archived Receivals","useServerPagination":""},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
        var item = ref.item;
return [_c('BT-List-Item-Avatar',{model:{value:(item.sellerID),callback:function ($$v) {_vm.$set(item, "sellerID", $$v)},expression:"item.sellerID"}}),_c('v-list-item-content',[_c('v-list-item-title',[_c('BT-Entity',{attrs:{"itemText":"companyName","itemValue":item.sellerID,"navigation":"public-companies","single":"","useLocalCache":""}})],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toShortDateAndTime")(item.deliveredOn)))])],1)]}},{key:"destinationLocationID",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"navigation":"locations","itemValue":item.destinationLocationID,"itemText":"locationName"}})]}},{key:"sellerID",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"itemText":"companyName","itemValue":item.sellerID,"navigation":"public-companies","single":"","useLocalCache":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v("Supplier: ")]},proxy:true}],null,true)})]}},{key:"courierID",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"itemText":"companyName","itemValue":item.courierID,"navigation":"public-companies","single":"","useLocalCache":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v("Courier: ")]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }